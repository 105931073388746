<template>
  <Page title="Account Confirmation">
    <h3>Registration Submitted Successfully!</h3>
    <br />
    <p>Your account is now being reviewed for approval!</p>

    <AccountIdCardContent />
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import AccountIdCardContent from "@/components/clients/account/AccountIdCardContent";
export default {
  name: "AccountConfirm",
  components: { Page, AccountIdCardContent }
};
</script>

<style scoped></style>
